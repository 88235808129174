import React, { useState } from "react";
import LettersAndNumbers from "../assets/lettersandnumbers.jpeg";
import Numbers from "../assets/numbers.jpeg";
import ContentImage from "../assets/letters-and-numbers-N.jpeg"; // Import the additional image

const ContentWithImagesWhiteBg: React.FC = () => {
  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6">
        {/* Grid Layout for Two Content Blocks */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* First Content Block */}
          <div className="flex flex-col bg-gray-50 text-gray-900 p-8 shadow-md rounded-lg transition-shadow hover:shadow-lg">
            <h2 className="text-4xl font-bold mb-6 text-center font-typerighter">
              Letters & Numbers
            </h2>
            <img
              src={LettersAndNumbers}
              alt="Letters and Numbers"
              className="w-full h-auto object-cover mb-6 rounded-lg"
            />
            <div className="flex flex-col flex-grow text-lg leading-relaxed max-w-xl mx-auto">
              <p className="mb-4 font-speak-pro">
                Our letter and number shaped boxes are filled to the brim with a
                selection of fine charcuterie, cheeses, fruits, nuts, chutneys,
                and other nibbles. Alternatives for dietary needs are available
                upon request.
              </p>
              <p className="mb-4 font-speak-pro">
                We provide keep-cool packaging to maintain the freshness of your
                box for at least 24 hours after delivery if stored in a cool,
                dark place.
              </p>
              <p className="mb-4 font-speak-pro">
                When it’s time to serve, simply open the boxes and lay out your
                letters and numbers on a table for a show-stopping presentation!
              </p>
              <img
                src={ContentImage}
                alt="Example Display"
                className="w-full h-auto object-cover mb-6 rounded-lg"
              />
              <div className="mt-auto flex justify-center">
                <a
                  href="/contact-us"
                  className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-3 px-6 rounded-lg text-lg font-semibold font-typerighter hover:bg-gray-200 transition-colors"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>

          {/* Second Content Block */}
          <div className="flex flex-col bg-gray-50 text-gray-900 p-8 shadow-md rounded-lg transition-shadow hover:shadow-lg">
            <h2 className="text-4xl font-bold mb-6 text-center font-typerighter">
              Spell it Out
            </h2>
            <img
              src={Numbers}
              alt="Spell it Out"
              className="w-full h-auto object-cover mb-6 rounded-lg"
            />
            <div className="flex flex-col flex-grow text-lg leading-relaxed max-w-xl mx-auto">
              <p className="mb-4 font-speak-pro">
                Our A-Z and 0-9 boxes are designed to serve 4-5 guests depending
                on the event and appetite. We also offer a + shaped box and a
                heart, catering to 2-3 people.
              </p>
              <p className="mb-4 font-speak-pro">
                Planning an intimate gathering with 10-13 guests? Consider
                choosing H+J. For larger events, we can spell out:
              </p>
              <p className="mb-6 text-center text-2xl font-semibold text-gray-700 font-speak-pro">
                <span className="inline-block">💖 MR + MRS SMITH 💖</span>
                <br />
                <span className="inline-block">💖 WEDDING 💖</span>
                <br />
                <span className="inline-block">26 💖 7 💖 25</span>
              </p>
              <p className="mb-6 font-speak-pro">
                “HAPPY BIRTHDAY KATIE“ serves 72-90, or “KATIE 21” caters for
                28-35. You get the idea! We also offer palm leaf plates and
                cutlery, which you can collect or have delivered.
              </p>
              <p className="mb-6 font-speak-pro">
                Collection is available by arrangement from our base in
                Hinckley, Leicestershire. We also offer delivery within a
                30-mile radius.
              </p>
              <div className="mt-auto flex justify-center">
                <a
                  href="/contact-us"
                  className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-3 px-6 rounded-lg text-lg font-semibold font-typerighter hover:bg-gray-200 transition-colors"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* FAQ Section */}
        <div className="mt-12 bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-6 text-center font-typerighter">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="bg-[#f7fafb] p-4 rounded-lg cursor-pointer"
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="text-2xl font-semibold text-gray-900 font-speak-pro">
                    {faq.question}
                  </h3>
                  <span
                    className={`text-gray-600 ${
                      openFAQIndex === index ? "rotate-180" : ""
                    }`}
                  >
                    &#9662;
                  </span>
                </div>
                {openFAQIndex === index && (
                  <p className="text-gray-700 mt-2 text-lg font-speak-pro">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
        {/* CTA Banner */}
        <div className="mt-16 bg-[#F9F4F2] text-gray-900 py-12 px-8 rounded-lg shadow-lg text-center">
          <h2 className="text-5xl font-bold mb-4 font-typerighter">
            Ready to Make Your Event Unforgettable?
          </h2>
          <p className="text-xl mb-6 font-speak-pro">
            Get in touch with us today to start planning your custom charcuterie
            display. Whether it’s a wedding, birthday, or any other special
            occasion, we’re here to help you create something truly unique.
          </p>
          <a
            href="/contact-us"
            className="inline-block bg-[#F9F8F2] text-gray-900 py-3 px-8 rounded-lg text-lg font-semibold font-typerighter border-2 border-gray-400 hover:bg-gray-200 transition-colors"
          >
            Contact Us Now
          </a>
        </div>
      </div>
    </section>
  );
};

const faqData = [
  {
    question: "How many do the letter and numbers boxes feed?",
    answer:
      "Each letter and number has been cleverly designed to feed 4-5 adults as a generous starter or evening buffet. We also have a heart and a plus shape which serve 2-3. For example 'HOLLY 21' would serve 28-35 adults. '18' would serve 8-10.",
  },
  {
    question: "What is included in each box?",
    answer:
      "Each box contains a selection of charcuterie, cheeses, fruits, nuts, chutneys, and crackers. We also provide alternatives for dietary needs if requested.",
  },
  {
    question:
      "Are you able to cater for allergies and other dietary requirements?",
    answer:
      "Yes, we can provide individual boxes for those with allergies or dietary requirements. Or offer a completely vegetarian, vegan or cart menu. We do however work from a multi-use prep kitchen where all common allergens are handled.",
  },
  {
    question:
      "What delivery options are available for the letter and number boxes?",
    answer:
      "We offer free collection from LE10 at agreed times, or can deliver them to your home or venue for an extra fee. We offer keep-cool packaging so you don't have to worry about space in a fridge (compulsory for deliveries, optional for collections). We provide super easy to follow instructions on how to lay them out at an event, for the ultimate insta-worthy spread in no time!",
  },
  {
    question:
      "Do you come and collect the letter and number boxes after an event?",
    answer:
      "No, they are made from single-use, food-safe cardboard. They are not suitable for re-use but can be recycled. Our packaging is also fully recyclable.",
  },
  {
    question: "Can I pick up my boxes from you?",
    answer:
      "Yes, our base is in Hinckley, Leicestershire, and you are welcome to pick up your boxes from us.",
  },
];

export default ContentWithImagesWhiteBg;
