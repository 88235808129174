import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo-spelt-grazing.webp"; // Adjust the path if needed
import "../fonts.css"; // Ensure this file is correctly imported
import { supabase } from "../supabase";

const StaffTopNav: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await supabase.auth.signOut();
    navigate("/login");
  };

  return (
    <nav className="bg-[#F9F4F2] text-gray-800 shadow-md fixed w-full top-0 left-0 z-20 desktop-navbar">
      <div className="container mx-auto px-4">
        <div className="relative flex items-center justify-between lg:flex-row lg:justify-between">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img
              src={logo}
              alt="Company Logo"
              className="h-20 w-auto" // Adjust the size as needed
            />
          </Link>

          {/* Mobile Menu Button */}
          <div className="lg:hidden flex items-center">
            <button
              className="text-gray-800 focus:outline-none ml-2" // Adjusted margin to move button away from the edge
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  <span className="sr-only">Close menu</span>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                  <span className="sr-only">Open menu</span>
                </>
              )}
            </button>
          </div>

          {/* Desktop Navigation Links */}
          <div className={`hidden lg:flex lg:flex-row lg:space-x-6 lg:ml-auto`}>
            <Link
              to="/upload"
              className={`block px-4 py-2 font-typerighter text-lg hover:text-blue-500 transition-colors ${
                location.pathname === "/upload" ? "font-bold" : ""
              }`}
            >
              Upload Images To Server
            </Link>
            <Link
              to="/gallery-admin"
              className={`block px-4 py-2 font-typerighter text-lg hover:text-blue-500 transition-colors ${
                location.pathname === "/gallery-admin" ? "font-bold" : ""
              }`}
            >
              Add Images To Gallery
            </Link>
            <Link
              to="/gallery-manager"
              className={`block px-4 py-2 font-typerighter text-lg hover:text-blue-500 transition-colors ${
                location.pathname === "/gallery-manager" ? "font-bold" : ""
              }`}
            >
              Delete Images From Gallery
            </Link>
            <button
              onClick={() => handleLogout()}
              className="block px-4 py-2 font-typerighter text-lg hover:text-blue-500 transition-colors"
            >
              Logout
            </button>
          </div>
        </div>

        {/* Mobile Menu - Expanded */}
        {isOpen && (
          <div className="lg:hidden bg-[#F9F4F2] text-gray-800 shadow-md">
            <div className="flex flex-col space-y-4 py-4 px-6">
              <Link
                to="/upload"
                className={`block font-typerighter text-lg hover:text-blue-500 transition-colors ${
                  location.pathname === "/upload" ? "font-bold" : ""
                }`}
                onClick={() => setIsOpen(false)}
              >
                Upload Images To Server
              </Link>
              <Link
                to="/gallery-admin"
                className={`block font-typerighter text-lg hover:text-blue-500 transition-colors ${
                  location.pathname === "/gallery-admin" ? "font-bold" : ""
                }`}
                onClick={() => setIsOpen(false)}
              >
                Add Images To Gallery
              </Link>
              <Link
                to="/gallery-manager"
                className={`block font-typerighter text-lg hover:text-blue-500 transition-colors ${
                  location.pathname === "/gallery-manager" ? "font-bold" : ""
                }`}
                onClick={() => setIsOpen(false)}
              >
                Delete Images From Gallery
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="block font-typerighter text-lg hover:text-blue-500 transition-colors"
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default StaffTopNav;
