// src/pages/Reviews.tsx
import React from "react";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import Hero from "../components/hero";

const Reviews: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero pageName="Reviews" />
      {/* Header Section */}
      <header className="bg-[#F9F4F2] text-gray-900 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-extrabold font-typerighter">Reviews</h1>
        </div>
      </header>
      <div className="flex flex-col min-h-screen bg-gray-50">
        <main className="flex-grow py-16">
          <div className="max-w-7xl mx-auto px-6">
            {/* Review Submission Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 text-center mb-12">
              <h2 className="text-2xl font-bold mb-4 font-typerighter">
                We Value Your Feedback
              </h2>
              <p className="text-lg text-gray-700 font-speak-pro">
                Let us know how we did! Submit your reviews via the contact us
                page.
              </p>
            </div>

            {/* Customer Reviews Section */}
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              <h2 className="text-2xl font-bold mb-6 font-typerighter">
                What Our Customers Are Saying
              </h2>
              <div className="space-y-6">
                <div className="bg-[#f7fafb] p-4 rounded-lg">
                  {/* Review 1 */}
                  <div className="border-b border-gray-300 pb-4 mb-4">
                    <h3 className="text-xl font-semibold mb-2 text-gray-800">
                      Personalised Pizzas
                    </h3>
                    <p className="text-gray-700 text-lg">
                      Holly and team provided all our wedding food from canapés,
                      to wedding breakfast pizza to grazing boxes for the
                      evening. We even requested our own personalised pizzas
                      which turned out even better than expected! The evening
                      graze boxes were full of really tasty, high quality
                      antipasti and there was plenty to choose from, the perfect
                      evening snack. The meat free alternatives were highly
                      appreciated also. They were displayed in the form of our
                      names, which added a really nice personalised touch. All
                      our guests mentioned how good the food was, highly
                      recommend!
                    </p>
                    <p className="mt-2 text-gray-600 font-semibold">- Alice</p>
                  </div>
                </div>
                {/* Add more reviews here with similar structure */}
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Reviews;
