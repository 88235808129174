import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

interface ContentProps {
  backgroundColor: string;
}

const Content: React.FC<ContentProps> = ({ backgroundColor }) => {
  return (
    <section
      className={`bg-[${backgroundColor}] py-6 relative overflow-hidden`}
    >
      <div className="relative w-full h-auto lg:h-[600px] flex items-center justify-center bg-[#F9F8F2] shadow-lg border border-gray-200 px-4 lg:px-0">
        <div className="text-center p-4 lg:p-8">
          <h1 className="text-3xl lg:text-5xl font-bold mb-4 lg:mb-6 text-gray-900 font-typerighter">
            Grazing Catering with a Personal Touch
          </h1>
          <p className="text-base lg:text-xl mb-6 lg:mb-8 text-gray-700 font-speak-pro">
            Looking for an impressive grazing catering option for your event? At
            SPELT grazing we take it another step further, with options to very
            literally personalise our delicious spreads. Whether you’d like to
            spell out your name (or anything really!) in mouthwatering cheeses
            and charcuterie, or treat your guests to their own wooden grazing
            board loaded up with all their favourite nibbles… our services are
            sure to impress! We have a range of packages to suit all occasions,
            whether it’s a light addition to a drinks reception, or a main meal
            with accompanying fresh salads and breads. Serving a 30-50 mile
            radius from our base in Hinckley.
          </p>

          {/* Subheading for Social Media Links */}
          <h2 className="text-xl lg:text-2xl font-bold mb-4 text-gray-900 font-typerighter">
            Follow Us On Social Media
          </h2>

          {/* Social Media Links Section */}
          <div className="flex justify-center space-x-4 lg:space-x-6 mb-6 lg:mb-8">
            <a
              href="https://www.facebook.com/speltgrazing"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook className="text-gray-700 hover:text-gray-900 text-2xl" />
            </a>
            <a
              href="https://www.instagram.com/speltgrazing"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="text-gray-700 hover:text-gray-900 text-2xl" />
            </a>
          </div>

          <a
            href="/contact-us"
            className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-2 px-4 lg:py-3 lg:px-6 rounded-lg font-semibold text-sm lg:text-lg hover:bg-gray-200 transition-colors font-typerighter"
          >
            Contact Us Now To Book
          </a>
        </div>
      </div>

      {/* What Our Customers Say Section */}
      <div className="mt-10 lg:mt-16 text-center px-4">
        <h2 className="text-2xl lg:text-3xl font-bold mb-4 lg:mb-6 font-typerighter">
          What Our Customers Say
        </h2>
        <div className="flex flex-wrap justify-center gap-6 lg:gap-8">
          <div className="bg-white p-4 lg:p-6 shadow-lg rounded-lg max-w-md mx-auto font-speak-pro">
            <p className="text-base lg:text-lg mb-2 lg:mb-4">
              Loved our work? Contact us to leave a review!
            </p>
            <p className="font-semibold"></p>
          </div>
          <div className="bg-white p-4 lg:p-6 shadow-lg rounded-lg max-w-md mx-auto font-speak-pro">
            <p className="text-base lg:text-lg mb-2 lg:mb-4">
              Loved our work? Contact us to leave a review!
            </p>
            <p className="font-semibold"></p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
