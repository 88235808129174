import React, { useState } from "react";

interface ContentWithMainImageProps {
  mainImage: string;
  additionalImage: string;
  title: string;
  additionalTitle: string;
  callToActionLink: string;
  callToActionText: string;
}

const ContentWithMainImage: React.FC<ContentWithMainImageProps> = ({
  mainImage,
  additionalImage,
  title,
  additionalTitle,
  callToActionLink,
  callToActionText,
}) => {
  const [isMainImageZoomed, setIsMainImageZoomed] = useState(false);
  const [isAdditionalImageZoomed, setIsAdditionalImageZoomed] = useState(false);
  const [openFAQIndex, setOpenFAQIndex] = useState<number | null>(null);

  const toggleMainImageZoom = () => setIsMainImageZoomed(!isMainImageZoomed);
  const toggleAdditionalImageZoom = () =>
    setIsAdditionalImageZoomed(!isAdditionalImageZoomed);
  const toggleFAQ = (index: number) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <section className="bg-white py-8">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="relative flex items-center justify-center">
            <img
              src={mainImage}
              alt={title}
              className={`w-full h-auto object-cover rounded-lg shadow-lg transition-transform ${
                isMainImageZoomed ? "transform scale-110" : ""
              }`}
              loading="lazy"
              onClick={toggleMainImageZoom}
            />
            {isMainImageZoomed && (
              <button
                className="absolute top-2 right-2 bg-gray-800 text-white p-2 rounded-full"
                onClick={toggleMainImageZoom}
              >
                Close
              </button>
            )}
          </div>
          <div className="flex flex-col bg-[#f7fafb] text-gray-900 p-6 shadow-lg rounded-lg">
            <h1 className="text-4xl font-bold mb-6 text-center font-typerighter">
              {title}
            </h1>
            <div className="flex flex-col flex-grow text-lg leading-relaxed text-center max-w-md mx-auto font-speak-pro">
              <p className="mb-4">
                Our beautiful grazing cart can be personalized for any occasion,
                offering a range of options.
              </p>
              <p className="mb-4">
                With 12-13 compartments, we serve a variety of charcuterie,
                cheeses, fruits, vegetables, nuts, and more. Alternatively, opt
                for a luxury breakfast with pastries, jams, cheeses, and fresh
                fruits.
              </p>
              <p className="mb-4">
                Fully serviced by our staff, your guests can choose from an
                array of goodies, served on wooden grazing boards or palm leaf
                dishes.
              </p>
              <p className="mb-6">
                Perfect for weddings, parties, or as a full main meal, our cart
                service is available within a 50-mile radius of Hinckley,
                Leicestershire.
              </p>
              <a
                href={callToActionLink}
                className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-3 px-6 rounded-lg text-lg font-semibold hover:bg-[#F1F1F1] transition-colors font-typerighter"
              >
                {callToActionText}
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
            <div className="flex flex-col bg-[#f7fafb] text-gray-900 p-6 shadow-lg rounded-lg">
              {additionalTitle && (
                <h2 className="text-4xl font-bold mb-6 text-center font-typerighter">
                  {additionalTitle}
                </h2>
              )}
              <div className="flex flex-col flex-grow text-lg leading-relaxed text-center max-w-md mx-auto font-speak-pro">
                <p className="mb-4">
                  Our cart features a large LED screen, for displaying custom
                  messages or branding.
                </p>
                <p className="mb-4">
                  We usually feature our logo, and a message about dietary
                  requirements, but why not also add a little message from you
                  to your wedding or party guests? Perhaps a baby photo of the
                  birthday girl/boy, or a photo of the happy couple?
                </p>
                <p className="mb-4">
                  The screen is also perfect for corporate occasions. You could
                  show off images of your new products, QR codes to your
                  website, messages to your clients, or simply your own company
                  logo.
                </p>
                <p className="mb-4">
                  Contact us to discuss how we can tailor this screen to
                  complement your event.
                </p>
                <a
                  href="/contact-us"
                  className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-3 px-6 rounded-lg text-lg font-semibold hover:bg-[#F1F1F1] transition-colors font-typerighter"
                >
                  Contact Us
                </a>
              </div>
            </div>
            <div className="relative flex items-center justify-center">
              <img
                src={additionalImage}
                alt={additionalTitle}
                className={`w-full h-auto object-cover rounded-lg shadow-lg transition-transform ${
                  isAdditionalImageZoomed ? "transform scale-110" : ""
                }`}
                loading="lazy"
                onClick={toggleAdditionalImageZoom}
              />
              {isAdditionalImageZoomed && (
                <button
                  className="absolute top-2 right-2 bg-gray-800 text-white p-2 rounded-full"
                  onClick={toggleAdditionalImageZoom}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
        {/* FAQ Section */}
        <div className="mt-12 bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-4xl font-bold mb-6 text-center font-typerighter">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqData.map((faq, index) => (
              <div
                key={index}
                className="bg-[#f7fafb] p-4 rounded-lg cursor-pointer"
              >
                <div
                  className="flex justify-between items-center"
                  onClick={() => toggleFAQ(index)}
                >
                  <h3 className="text-2xl font-semibold text-gray-900 font-speak-pro">
                    {faq.question}
                  </h3>
                  <span
                    className={`text-gray-600 ${
                      openFAQIndex === index ? "rotate-180" : ""
                    }`}
                  >
                    &#9662;
                  </span>
                </div>
                {openFAQIndex === index && (
                  <p className="text-gray-700 mt-2 text-lg font-speak-pro">
                    {faq.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
const faqData = [
  {
    question: "Does the cart come with a server and do you offer dry hire?",
    answer:
      "The cart is only available as a package including both the food and a server. The server will set up the cart and serve people for the agreed time period. We do not offer the cart for hire without food and/or a server.",
  },
  {
    question:
      "Are you able to cater for allergies and other dietary requirements?",
    answer:
      "Yes, we can provide individual boxes for those with allergies or dietary requirements. We can also offer a completely vegetarian, vegan, or custom menu. However, we work from a multi-use prep kitchen where common allergens are handled.",
  },
  {
    question: "How big is the cart?",
    answer:
      "The cart is 60cm deep, 120cm wide, and 1m high. We also need a little extra room for setting up and to stand behind the cart to serve. We can serve inside or outside, weather permitting!",
  },
  {
    question: "How long do you need to set up?",
    answer:
      "We need to arrive 45 minutes to 1 hour before the serving time to prepare the cart.",
  },
];
export default ContentWithMainImage;
