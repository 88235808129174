import React, { useState } from "react";

const ContactForm: React.FC = () => {
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [eventType, setEventType] = useState("");
  const [guests, setGuests] = useState<number | string>("");
  const [date, setDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [venue, setVenue] = useState("");
  const [email, setEmail] = useState("");
  const [cart, setCart] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const [honeypot, setHoneypot] = useState(""); // Honeypot field

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check honeypot field
    if (honeypot) {
      setError(true);
      return;
    }

    try {
      const response = await fetch("/send-email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          role,
          eventType,
          guests,
          date,
          startTime,
          venue,
          email,
          cart,
          subject,
          message,
        }),
      });

      if (response.ok) {
        setSubmitted(true);
        setName("");
        setRole("");
        setEventType("");
        setGuests("");
        setDate("");
        setStartTime("");
        setVenue("");
        setEmail("");
        setCart("");
        setSubject("");
        setMessage("");
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <main className="flex-grow">
        <section className="py-16 bg-gray-50">
          <div className="max-w-7xl mx-auto px-6">
            <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 text-center">
              <h2 className="text-2xl font-bold mb-4 font-typerighter">
                Contact Us
              </h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                {/* Honeypot Field */}
                <div className="hidden">
                  <label htmlFor="honeypot"></label>
                  <input
                    type="text"
                    id="honeypot"
                    value={honeypot}
                    onChange={(e) => setHoneypot(e.target.value)}
                    className="border rounded-lg px-4 py-2"
                  />
                </div>

                {/* Name */}
                <div>
                  <label
                    htmlFor="name"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Role */}
                <div>
                  <label
                    htmlFor="role"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Your Role (Bride, Groom, Event Planner, etc.)
                  </label>
                  <input
                    type="text"
                    id="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Event Type */}
                <div>
                  <label
                    htmlFor="eventType"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Event Type (Wedding, Birthday, Corporate, etc.)
                  </label>
                  <input
                    type="text"
                    id="eventType"
                    value={eventType}
                    onChange={(e) => setEventType(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Number of Guests */}
                <div>
                  <label
                    htmlFor="guests"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Number of Guests
                  </label>
                  <input
                    type="number"
                    id="guests"
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Event Date */}
                <div>
                  <label
                    htmlFor="date"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Event Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Start Time of Service */}
                <div>
                  <label
                    htmlFor="startTime"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Start Time of Service
                  </label>
                  <input
                    type="time"
                    id="startTime"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Venue Address */}
                <div>
                  <label
                    htmlFor="venue"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Venue Address
                  </label>
                  <input
                    type="text"
                    id="venue"
                    value={venue}
                    onChange={(e) => setVenue(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Email */}
                <div>
                  <label
                    htmlFor="email"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Cart or Letters/Numbers */}
                <div>
                  <label
                    htmlFor="cart"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Cart or Letters/Numbers
                  </label>
                  <input
                    type="text"
                    id="cart"
                    value={cart}
                    onChange={(e) => setCart(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                {/* Subject */}
                <div>
                  <label
                    htmlFor="subject"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Message */}
                <div>
                  <label
                    htmlFor="message"
                    className="block text-left text-lg font-semibold mb-2 font-speak-pro"
                  >
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={5}
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    required
                  />
                </div>

                {/* Submit Button */}
                <div className="mt-6 flex justify-center">
                  <button
                    type="submit"
                    className="inline-block bg-[#F9F8F2] border-2 border-gray-400 text-gray-900 py-3 px-6 rounded-lg text-lg font-semibold font-typerighter transition-colors duration-300 hover:bg-gray-200 hover:border-gray-500"
                  >
                    Send Message
                  </button>
                </div>
              </form>

              {submitted && (
                <div className="mt-4 text-green-600 font-speak-pro">
                  Thank you for your message! We will get back to you soon.
                </div>
              )}

              {error && (
                <div className="mt-4 text-red-600 font-speak-pro">
                  Something went wrong. Please try again.
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ContactForm;
