import React from "react";
import Hero from "../components/hero";
import Footer from "../components/Footer";
import ContentWithImagesWhiteBg from "../components/ContentWithImagesWhiteBg";
import TopNavigation from "../components/TopNavigation";

const LettersAndNumbers: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero pageName="Letters & Numbers" />
            {/* Header Section */}
            <header className="bg-[#F9F4F2] text-gray-900 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-extrabold font-typerighter">
            Letters & Numbers
          </h1>
        </div>
      </header>
      <ContentWithImagesWhiteBg />
      <Footer />
    </>
  );
};

export default LettersAndNumbers;
