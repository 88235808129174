import React, { useEffect, useState } from "react";
import TopNavigation from "../components/TopNavigation";
import StaffTopNav from "../components/StaffTopNav";
import Hero from "../components/hero";
import Content from "../components/Content";
import Footer from "../components/Footer";
import { supabase } from "../supabase";

const HomePage: React.FC = () => {
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    // Get the current session
    const getSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session);
    };

    getSession();

    // Listen for changes to the auth state
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    // Clean up the listener on component unmount
    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <div>
      {session ? (
        <>
          <StaffTopNav />
          <Hero pageName={"Staff Homepage"} />
          <Content backgroundColor={"bg-[#F9F4F2]"} />
          <Footer />
        </>
      ) : (
        <>
          <TopNavigation />
          <Hero/>
          <Content backgroundColor={"bg-[#F9F4F2]"} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default HomePage;
