import React from "react";
import Hero from "../components/hero";
import Footer from "../components/Footer";
import GrazingCartMainImage from "../assets/grazing-cart.jpeg";
import GrazingCartSecondaryImage from "../assets/grazing-cart-image-2.jpeg";
import ContentWithMainImage from "../components/ContentWithMainImage";
import TopNavigation from "../components/TopNavigation";

const GrazingCart: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero pageName={"Grazing Cart"} />
      {/* Header Section */}
      <header className="bg-[#F9F4F2] text-gray-900 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-extrabold font-typerighter">
            Grazing Cart
          </h1>
        </div>
      </header>
      <ContentWithMainImage
        title={"Grazing Cart"}
        callToActionLink={"/contact-us"}
        callToActionText={"Contact Us"}
        mainImage={GrazingCartMainImage}
        additionalImage={GrazingCartSecondaryImage}
        additionalTitle={"Add your message or company name"}
      />
      <Footer />
    </>
  );
};

export default GrazingCart;
