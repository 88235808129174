import React from "react";
import { Link } from "react-router-dom";
import "../index.css"; // Ensure this path is correct
import "@fortawesome/fontawesome-free/css/all.min.css";

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="container mx-auto text-center">
        <p className="text-gray-700">
          &copy; {new Date().getFullYear()} SPELT Grazing. All rights reserved.
          Designed by <Link to="https://www.hostwithmike.com">www.hostwithmike.com</Link>
          <i className="fas fa-user-astronaut"></i>
        </p>
        <Link to="/login" className="text-blue-600 hover:underline">
          Staff Login
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
