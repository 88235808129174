// src/pages/ContactUs.tsx
import React, { useState } from "react";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import Hero from "../components/hero";
import SendEmail from "../components/SendEmail";

const ContactUs: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero pageName={"Contact Us"} />
      {/* Header Section */}
      <header className="bg-[#F9F4F2] text-gray-900 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <h1 className="text-4xl font-extrabold font-typerighter">Contact Us</h1>
        </div>
      </header>
      <SendEmail />
      <Footer />
    </>
  );
};

export default ContactUs;
