import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { supabase } from "../supabase";

const ImageGallerySelector: React.FC = () => {
  const [images, setImages] = useState<{ name: string; url: string }[]>([]);
  const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchImages = async () => {
      const { data, error } = await supabase.storage
        .from("speltuploads")
        .list("images/");
      if (error) {
        console.error("Error fetching images:", error.message);
        return;
      }

      const imageUrls =
        data?.map((file) => {
          const { data } = supabase.storage
            .from("speltuploads")
            .getPublicUrl(`images/${file.name}`);

          return {
            name: file.name,
            url: data.publicUrl,
          };
        }) || [];

      setImages(imageUrls);
    };

    fetchImages();
  }, []);

  const handleSelectionChange = (name: string) => {
    setSelectedImages((prev) => {
      const updatedSelection = new Set(prev);
      if (updatedSelection.has(name)) {
        updatedSelection.delete(name);
      } else {
        updatedSelection.add(name);
      }
      return updatedSelection;
    });
  };

  const handleSubmit = async () => {
    console.log("Selected images:", Array.from(selectedImages));

    try {
      const selectedImagesArray = Array.from(selectedImages);

      // Loop through the selected images and insert them into a Supabase table called 'gallery'
      for (const imageUrl of selectedImagesArray) {
        const { data, error } = await supabase
          .from("gallery") // Assuming you have a table named 'gallery'
          .insert([{ imageUrl }]); // Insert each image URL

        if (error) throw error;
        console.log("Image added to gallery:", data);
      }

      // Clear the selection after successful submission
      setSelectedImages(new Set());

      alert("Images added to gallery successfully!");
    } catch (error) {
      console.error("Error adding images to gallery:", error);
    }
  };

  // Determine if there are other images besides the .emptyFolderPlaceholder
  const imagesWithoutPlaceholder = images.filter(
    (image) => image.name !== ".emptyFolderPlaceholder"
  );
  const hasImages = imagesWithoutPlaceholder.length > 0;
  const showPlaceholder =
    images.length === 1 && images[0].name === ".emptyFolderPlaceholder";

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow bg-gradient-to-r from-blue-200 via-purple-300 to-pink-200 flex items-center justify-center py-8">
        <div className="w-full max-w-lg mx-auto bg-white shadow-lg rounded-lg p-8">
          <h2 className="text-3xl font-semibold text-center mb-6">
            Select Images for Gallery
          </h2>
          {hasImages ? (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {imagesWithoutPlaceholder.map((image) => (
                  <div
                    key={image.name}
                    className={`relative border p-2 rounded-lg cursor-pointer ${
                      selectedImages.has(image.name)
                        ? "border-blue-500"
                        : "border-gray-300"
                    }`}
                    onClick={() => handleSelectionChange(image.name)}
                  >
                    <img
                      src={image.url}
                      alt={image.name}
                      className="w-full h-auto object-cover rounded-lg"
                    />
                    <div className="absolute top-0 right-0 bg-blue-500 text-white p-1 text-sm rounded-bl-md">
                      {selectedImages.has(image.name) ? "Selected" : "Select"}
                    </div>
                  </div>
                ))}
              </div>
              <button
                onClick={handleSubmit}
                className="mt-6 w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg hover:bg-blue-600 transition"
              >
                Submit Selection
              </button>
            </>
          ) : showPlaceholder ? (
            <p className="text-center text-gray-700 mt-4">
              No images to select
            </p>
          ) : (
            <p className="text-center text-gray-700 mt-4">
              No images available
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageGallerySelector;
