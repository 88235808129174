// src/pages/GalleryAdmin.tsx
import React from "react";
import ImageGallerySelector from "../components/ImageGallerySelector";
import StaffTopNav from "../components/StaffTopNav";
import Footer from "../components/Footer";

const GalleryAdmin: React.FC = () => {
  return (
    <>
      <StaffTopNav />
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow">
          <ImageGallerySelector />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default GalleryAdmin;
