import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopNavigation from "../components/TopNavigation";
import Footer from "../components/Footer";
import { supabase } from "../supabase";
import Modal from "react-modal";

Modal.setAppElement("#root"); // For accessibility

const Gallery: React.FC = () => {
  const [images, setImages] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  useEffect(() => {
    const fetchImages = async () => {
      const { data, error } = await supabase.from("gallery").select("imageUrl");

      if (error) {
        console.error("Error fetching images:", error.message);
        setLoading(false);
      } else {
        console.log("Fetched data:", data); // Debugging line

        // Filter out empty or invalid URLs
        const validImageUrls = data
          .map((item: { imageUrl: string }) => item.imageUrl)
          .filter((url) => url && url.startsWith("https://"));

        console.log("Final image URLs:", validImageUrls); // Debugging line

        setImages(validImageUrls);
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const openModal = (url: string) => {
    setSelectedImage(url);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    arrows: true,
  };

  return (
    <>
      <TopNavigation />

      <div className="gallery-slider-container max-w-screen-lg mx-auto p-4">
        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="loader">Loading...</div>{" "}
            {/* Add your loader styles here */}
          </div>
        ) : (
          <Slider {...settings}>
            {images.length > 0 ? (
              images.map((url, index) => (
                <div
                  key={index}
                  className="gallery-slide relative overflow-hidden"
                >
                  <img
                    src={url}
                    alt={`Gallery Image ${index}`}
                    className="w-full h-auto object-cover rounded-lg shadow-lg cursor-pointer transition-transform transform hover:scale-105"
                    onClick={() => openModal(url)}
                    onError={(e) => {
                      e.currentTarget.src = "path/to/placeholder-image.jpg"; // Fallback image
                    }}
                  />
                </div>
              ))
            ) : (
              <p className="text-center">No images found.</p>
            )}
          </Slider>
        )}
      </div>

      {/* Modal for Full-Size Image */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <button
          onClick={closeModal}
          className="modal-close-button absolute top-4 right-5 text-black text-3xl"
        >
          &times;
        </button>
        {selectedImage && (
          <div className="relative h-full w-full flex items-center justify-center">
            <img
              src={selectedImage}
              alt="Full Size"
              className="max-w-full max-h-full object-contain"
            />
          </div>
        )}
      </Modal>

      {/* CTA Banner */}
      <div className="mt-16 bg-[#F9F4F2] text-gray-900 py-12 px-8 rounded-lg shadow-lg text-center">
        <h2 className="text-4xl font-bold mb-4 font-typerighter">
          Ready to Share Your Event Memories?
        </h2>
        <p className="text-lg mb-6 font-speak-pro">
          We'd love to feature your event photos in our gallery! Whether it's a
          wedding, birthday, or any special occasion, your memories can inspire
          others. Click the button below to send us your photos and let us help
          you showcase your unforgettable moments.
        </p>
        <a
          href="/contact-us"
          className="inline-block bg-[#F9F8F2] text-gray-900 py-3 px-8 rounded-lg text-lg font-semibold border-2 border-gray-400 hover:bg-gray-200 transition-colors font-typerighter"
        >
          Send Your Photos
        </a>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
