import React from "react";
import Hero from "../components/hero";
import Footer from "../components/Footer";
import TopNavigation from "../components/TopNavigation";

const SampleMenu: React.FC = () => {
  return (
    <>
      <TopNavigation />
      <Hero pageName={"Grazing Cart Sample Menu"} />
      <div className="flex flex-col min-h-screen bg-gray-50">
        <main className="flex-grow">
          <section className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-6">
              <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 text-center">
                <h2 className="text-2xl font-bold mb-4 font-typerighter">
                  Sample Menu
                </h2>
                <p className="text-lg text-gray-700">
                  Our sample menu includes a variety of grazing options for your
                  events. The items listed are customizable based on your
                  preferences and dietary needs. Contact us to create your
                  perfect spread!
                </p>
              </div>
            </div>
          </section>

          {/* Signature Grazing */}
          <section id="signature-grazing" className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Our Signature Grazing
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                For the perfect board, we recommend choosing 3 meats, 3 cheeses,
                3 fruits/vegetables, and 3 extra nibbles.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {/* Meats/Alternatives */}
                <div>
                  <h3 className="text-2xl font-semibold mb-4">
                    Meats/Alternatives
                  </h3>
                  <ul className="list-disc pl-6 text-gray-700">
                    <li>Milano salami</li>
                    <li>Peppered salami</li>
                    <li>Chorizo</li>
                    <li>Prosciutto</li>
                    <li>Mortadella</li>
                    <li>MEAT FREE falafel</li>
                    <li>MEAT FREE sausages</li>
                  </ul>
                </div>

                {/* Cheeses */}
                <div>
                  <h3 className="text-2xl font-semibold mb-4">Cheeses</h3>
                  <ul className="list-disc pl-6 text-gray-700">
                    <li>Cheddar</li>
                    <li>Brie</li>
                    <li>Smoked gouda</li>
                    <li>Hard goat cheese</li>
                    <li>Red Leicester</li>
                    <li>Manchego</li>
                  </ul>
                </div>

                {/* Fruits/Vegetables */}
                <div>
                  <h3 className="text-2xl font-semibold mb-4">
                    Fruits/Vegetables
                  </h3>
                  <ul className="list-disc pl-6 text-gray-700">
                    <li>Grapes</li>
                    <li>Olives</li>
                    <li>Dried apricots</li>
                    <li>Strawberries</li>
                    <li>Blueberries</li>
                  </ul>
                </div>

                {/* Extras/Nibbles */}
                <div>
                  <h3 className="text-2xl font-semibold mb-4">
                    Extras/Nibbles
                  </h3>
                  <ul className="list-disc pl-6 text-gray-700">
                    <li>Crackers</li>
                    <li>Breadsticks</li>
                    <li>Pretzels</li>
                    <li>Crostini</li>
                    <li>Croutons</li>
                    <li>Almonds</li>
                    <li>Walnuts</li>
                    <li>Onion relish</li>
                    <li>Tomato chutney</li>
                    <li>Honey</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          {/* Brunch */}
          <section id="brunch" className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Brunch
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                Set menu consisting of:
              </p>
              <ul className="list-disc pl-6 text-gray-700">
                <li>Mini pastries</li>
                <li>Mini croissants</li>
                <li>Blueberry muffins</li>
                <li>Greek yogurt</li>
                <li>Honey pots</li>
                <li>Jam pots</li>
                <li>Granola</li>
                <li>Swiss cheese</li>
                <li>English ham</li>
                <li>Strawberries, blueberries, grapes</li>
              </ul>
            </div>
          </section>

          {/* Ploughmans */}
          <section id="ploughmans" className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Ploughmans
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                Set menu consisting of:
              </p>
              <ul className="list-disc pl-6 text-gray-700">
                <li>English cheddar</li>
                <li>Red Leicester</li>
                <li>Apple slices</li>
                <li>Grapes</li>
                <li>Celery sticks</li>
                <li>English ham</li>
                <li>Sausage rolls</li>
                <li>Mini pork pies</li>
                <li>Cornichons</li>
                <li>Pickled onions</li>
                <li>Chutney pots</li>
                <li>Baguette slices and butter</li>
              </ul>
            </div>
          </section>

          {/* CHAATcuterie */}
          <section id="chaatcuterie" className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                CHAATcuterie
              </h2>
              <p className="text-lg text-gray-700 mb-8">Choose any 12:</p>
              <ul className="list-disc pl-6 text-gray-700">
                <li>Chakri/sev</li>
                <li>Naan bites</li>
                <li>Green mango chutney</li>
                <li>Vegetable samosas</li>
                <li>Vegetable pakoras</li>
                <li>Spiced chickpeas</li>
                <li>Pomegranate seeds</li>
                <li>Mini baked potatoes</li>
                <li>Onion salad</li>
                <li>Yogurt dip</li>
                <li>Fried paneer</li>
                <li>Spicy chicken</li>
                <li>Mini popadoms</li>
                <li>Maggi hot and sweet ketchup</li>
                <li>Pani puri shells</li>
              </ul>
            </div>
          </section>

          {/* SPELT Grazing */}
          <section id="spelt-grazing" className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                SPELT Grazing
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                Our A-Z and 0-9 boxes feed 4-5 people as a generous starter or
                light evening nibbles. We also offer heart-shaped and "+" boxes
                for smaller groups.
              </p>
              <p className="text-lg text-gray-700 mb-8">
                Each box contains a variety of fine cheeses, charcuterie, and
                other delights, typically including:
              </p>
              <ul className="list-disc pl-6 text-gray-700">
                <li>Milano salami</li>
                <li>Napoli salami</li>
                <li>Chorizo</li>
                <li>Red Leicester</li>
                <li>Provolone</li>
                <li>Herby cream cheese swirl</li>
                <li>Falafel</li>
                <li>Olives</li>
                <li>Grapes</li>
                <li>Chutney</li>
                <li>Walnuts and almonds</li>
                <li>Dried apricots</li>
                <li>Cherry tomatoes</li>
                <li>Food-safe florals and herby garnishes</li>
              </ul>
              <p className="text-lg text-gray-700 mb-8">
                Boxes come with crackers and are customizable based on dietary
                needs. Just ask!
              </p>
            </div>
          </section>

          {/* Make a meal of it package */}
          <section id="meal-package" className="py-16 bg-gray-50">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                "Make a Meal of It" Package
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                Available with our Signature Grazing, Ploughmans, or Bespoke
                menus. We'll serve sharing bowls of salads and breads to dining
                tables or buffet-style, with extra staffing for a full grazing
                experience.
              </p>
              <ul className="list-disc pl-6 text-gray-700">
                <li>Herb, lemon, and feta couscous</li>
                <li>Roasted vegetable and tomato pesto pasta salad</li>
                <li>Rocket and tomato salad with balsamic dressing</li>
                <li>Rosemary and garlic focaccia</li>
              </ul>
            </div>
          </section>

          {/* Bespoke */}
          <section id="bespoke" className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-6">
              <h2 className="text-3xl font-bold mb-8 font-typerighter">
                Bespoke Options
              </h2>
              <p className="text-lg text-gray-700 mb-8">
                Want a personalized menu? We offer bespoke selections tailored
                to your event. Get in touch, and we'll create the perfect
                grazing experience for you.
              </p>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </>
  );
};

export default SampleMenu;
