import React from "react";
import logo from "../assets/logo-spelt-grazing.webp";
import heroBackground from "../assets/hero-background.webp";

interface HeroProps {
  pageName?: string;
}

const Hero: React.FC<HeroProps> = ({ pageName }) => {
  return (
    <section className="relative bg-[#F9F4F2] overflow-hidden">
      <div className="relative flex justify-center items-center h-[35vh] md:h-[45vh] lg:h-[50vh] xl:h-[60vh]">
        <div className="absolute inset-0">
          <img
            src={heroBackground}
            alt="Hero Background"
            className="w-full h-full object-cover opacity-100"
          />
        </div>
        {/* <img
          src={logo}
          alt="Hero Logo"
          className="w-auto h-[15vh] md:h-[22.5vh] lg:h-[25vh] xl:h-[30vh] max-w-full object-cover opacity-90"
        /> */}
      </div>
    </section>
  );
};

export default Hero;
