import React, { useState } from "react";
import Footer from "./Footer";
import StaffTopNav from "./StaffTopNav";
import { supabase } from "../supabase";

const ImageUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [message, setMessage] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    setUploading(true);

    // Upload the image
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from("speltuploads")
      .upload(`images/${file.name}`, file);

    if (uploadError) {
      console.error("Upload error:", uploadError.message);
      setMessage("Upload failed. Please try again.");
      setUploading(false);
      return;
    }

    // Get the public URL of the uploaded image
    const { data: urlData } = supabase.storage
      .from("speltuploads")
      .getPublicUrl(`images/${file.name}`);

    if (!urlData) {
      console.error("URL error: URL data not found.");
      setMessage("Failed to get image URL.");
      setUploading(false);
      return;
    }

    const publicUrl = urlData.publicUrl;

    // Insert the image URL into the gallery table
    const { error: insertError } = await supabase
      .from("gallery")
      .insert([{ imageUrl: publicUrl }]);

    if (insertError) {
      console.error("Insert error:", insertError.message);
      setMessage("Failed to add image to gallery.");
    } else {
      setMessage("Image uploaded and added to gallery successfully!");
    }

    setUploading(false);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <StaffTopNav />
      <div className="flex-grow">
        <div className="hero h-screen bg-gradient-to-r from-blue-200 via-purple-300 to-pink-200 flex items-center justify-center">
          <div className="max-w-lg mx-auto bg-white shadow-lg rounded-lg p-8">
            <h2 className="text-3xl font-semibold text-center mb-6">
              Upload Your Image
            </h2>
            <p className="text-center mb-6 text-gray-600">
              Please select an image file to upload. The image will be stored in
              our gallery for you to view later.
            </p>
            {message && (
              <div
                className={`mb-4 text-center p-2 rounded ${
                  message.includes("successfully")
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {message}
              </div>
            )}
            <div className="flex flex-col items-center">
              <input type="file" onChange={handleFileChange} className="mb-4" />
              <button
                onClick={handleUpload}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                disabled={uploading}
              >
                {uploading ? (
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                    ></path>
                  </svg>
                ) : (
                  "Upload"
                )}
              </button>
              {file && !uploading && (
                <p className="mt-4 text-gray-600">Selected file: {file.name}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ImageUpload;
